<template>
  <nav v-if="breadcrumbs.length > 1" aria-label="breadcrumb">
    <ol class="section-breadcrumbs">
      <li class="breadcrumb" v-for="(page, p) in breadcrumbs" :key="p">
        <a :href="page.url" tabindex="-1" v-text="page.label" :aria-current="p === breadcrumbs.length - 1 ? 'page' : null"></a>
      </li>
    </ol>
  </nav>
</template>
<script setup>
  const route = useRoute();

  const breadcrumbs = computed(() => {
    if (!route.path) return [];
    let paths = [];
    const replacements = {
      to: "to",
      and: "and",
      a: "a",
      sms: "SMS",
      mms: "MMS",
      ai: "AI",
      rcs: "RCS",
      api: "API",
      "2fa": "2FA",
      "sub processors": "Sub-processors",
      "not for profit": "Not-for-profit",
      "e commerce": "E-commerce",
      jobadder: "JobAdder",
      "active campaign": "ActiveCampaign",
    };

    const skipFolder = ["Messaging", "Engagement", "Features", "Connectivity", "Industries", "Use Cases"];

    let parents = route.path
      .split("/")
      .filter(Boolean)
      .map((p) => {
        paths.push(p);

        let pageTitle = textTitleCase(p);

        for (const [key, value] of Object.entries(replacements)) {
          pageTitle = pageTitle.replace(new RegExp(`\\b${key}\\b`, "gi"), value);
        }

        return {
          label: pageTitle,
          url: `/${paths.join("/")}`,
        };
      })
      .filter((p) => !skipFolder.includes(p.label));

    if (parents[1]?.label === "Open Roles") {
      parents.splice(2, 10);
    }

    return parents;
  });
</script>
